import CustomStore from "devextreme/data/custom_store";
import appInfo from "../app-info";
import { useContext, createContext } from "react";
import { useAuth } from "./auth";
import notify from "devextreme/ui/notify";


function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

function LibraryWordsProvider(props) {
    const store = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            if (!loadOptions.sort)
                loadOptions.sort = [{ desc: false, selector: 'turkishText' }]
            let params = '?';
            [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
            });
            params = params.slice(0, -1);
            return fetch(`${appInfo.apiUrl}/librarywords/${params}`,{
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token 
                  }
                })
                .then((response) => response.json())
                .then((data) => ({
                    data: data.data,
                    totalCount: data.totalCount,
                    summary: data.summary,
                    groupCount: data.groupCount
                }))
                .catch(() => { throw new Error('Data Loading Error'); });
        },
        insert: (values) => fetch(`${appInfo.apiUrl}/librarywords`, { method: "POST", headers: { 'content-type': 'application/json',"Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token  }, body: JSON.stringify(values) }).then(() => { notify('Kelime başarıyla eklenmiştir.', 'success', 5000) }).catch(() => { throw new Error('Data Insert Error'); }),
        update: (key, values) => fetch(`${appInfo.apiUrl}/librarywords/${key}`, { method: "PUT", headers: { 'content-type': 'application/json',"Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token  }, body: JSON.stringify(values) }).then(() => { notify('Kelime başarıyla güncellenmiştir.', 'success', 5000) }).catch(() => { throw new Error('Data Update Error'); }),
        remove: (key) => fetch(`${appInfo.apiUrl}/librarywords/${key}`, { method: "DELETE", headers: { 'content-type': 'application/json',"Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token  } }).then(() => { notify('Kelime başarıyla silinmiştir.', 'success', 5000) }).catch(() => { throw new Error('Data Delete Error'); }),

    });

    return (
        <LibraryWordsContext.Provider value={{ store }} {...props} />
    );
}


const LibraryWordsContext = createContext({ loading: false });
const useLibraryWords = () => useContext(LibraryWordsContext);

export { LibraryWordsProvider, useLibraryWords }
