import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { LibraryWordsProvider } from './contexts/librarywords';
import { GamesProvider } from './contexts/games';
import trMessages from 'devextreme/localization/messages/tr.json';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';


function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}
class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: this.getLocale(),
    };
 
    this.initMessages();
    locale(this.state.locale);
    this.changeLocale = this.changeLocale.bind(this);
  }

  getLocale() {
    const storageLocale = sessionStorage.getItem('locale');
    return storageLocale != null ? storageLocale : 'tr';
  }

  setLocale(savingLocale) {
    sessionStorage.setItem('locale', savingLocale);
  }

  initMessages() {
    loadMessages(trMessages);
  }

  changeLocale(e) {
    this.setState({
      locale: e.value,
    });
    this.setLocale(e.value);
    document.location.reload();
  }
  render() {
    return (
      <Router>
        <AuthProvider>
          <LibraryWordsProvider>
            <GamesProvider>
            <NavigationProvider>
              <div className={`app ${this.screenSizeClass}`}>
                <App />
              </div>
            </NavigationProvider>
            </GamesProvider>
          </LibraryWordsProvider>
           
        </AuthProvider>
        
      </Router>
    );
  }
}
export default Root