import appInfo from "../app-info";

export async function signIn(email, password) {
  try {

    const result = await fetch(`${appInfo.apiUrl}/account/token`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ userName: email, password: password })
    })
      .then(response => response.json());
    if (result.token) {
      localStorage.setItem('currentUser', JSON.stringify(result));
      return {
        isOk: true,
        data: result
      };
    }
    else throw "Unauthorized";
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    const currenUser = localStorage.getItem('currentUser');
    if (currenUser) 
      return {
        isOk: true,
        data: JSON.parse(currenUser)
      };
    else 
    return {
      isOk: false,
      message: "No current User"
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
