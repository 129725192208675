import {  DataGrid } from 'devextreme-react';
import CustomStore from "devextreme/data/custom_store";
import React, { useEffect, useRef, useState } from 'react';
import appInfo from '../../app-info';
import { GamesProvider, useGames } from '../../contexts/games';
import {
  Column,
  Paging,
  Pager,
  Selection,
  FilterRow,
  FilterPanel,
  Sorting,
  Editing,
  Button,
  Scrolling
} from 'devextreme-react/ui/data-grid';

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

export default function Games () {
    const { store } = useGames();
   

    function DownloadExcel(e){

      fetch(`${appInfo.apiUrl}/librarywords/GetByGameId?GameId=${e.id}`, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token,
          'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
      })
        .then(function (data) {
          return data.json(); // call the json method on the response to get JSON
      })
      .then(function (json) {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(json)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = e.id+ ".json";

        link.click();
      })
    }
    function DownloadSpeech(rowData){

      fetch(`${appInfo.apiUrl}/files/downloadlibrarywordspeechsbygameid/${rowData.id}`, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token,
          'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
      })
      .then(transfer => transfer.blob())
      .then(bytes => {
          let elm = document.createElement('a');
          elm.href = URL.createObjectURL(bytes);
          elm.setAttribute('download', `${rowData.id}.rar`);
          elm.click()
          return true;
      })
    }
    function cellRenderNumber(e) {
      return (
        <>
          {(((e.component.pageSize() * e.component.pageIndex()) + e.row.rowIndex) + 1).toString().padStart(2, '0')}
        </>
      )
    }

    function cellRenderNameText(row) {
      return row.value ? <div style={{ textAlign: 'left' }}> {row.value} <br /> {row.data.id}  </div>: <div style={{ textAlign: 'center' }}><i className='fa fa-remove' style={{ 'color': 'red' }}></i></div>;
    }
    
    const cellRenderSpeechCheck = row =>  <button disabled={row.data.hasSpeech ? "" : true} style={{border: 'none'}} stylingmode='text' type={'success'} className='fa fa-download fa-lg' text={"Oyun ses dosyasını indir"} onClick={() => { DownloadSpeech(row.data); }}></button>;
  
    return (
        <React.Fragment>
          <GamesProvider/>
          <h2 className={'content-block'}>Oyunlar</h2>
          <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
              <DataGrid   id='mainGrid' dataSource={store} showBorders={true} rowAlternationEnabled={true} 
              hoverStateEnabled={true} remoteOperations={true}>
                <Column name="column0" caption="#" dataField={'index'} cellRender={cellRenderNumber} alignment="left" width={60}></Column>
              <Column name="column00" caption="Çeviri İndir" type="buttons" width={80} >
                  <Button stylingMode='text' type={'success'} icon='fa fa-download' text={"Oyun çeviri dosyasını indir"} onClick={(e) => { DownloadExcel(e.row.data); }}></Button>
              </Column>
              <Column name="column000" caption="Ses İndir" type="buttons" width={80} cellRender={cellRenderSpeechCheck}></Column>
                <Column name="column2" alignment="left" caption="Oyun Adı" dataField="name" cellRender={cellRenderNameText}/>
                
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <Selection mode="single" />
                <Sorting mode="single"></Sorting>
                <Pager visible={true} allowedPageSizes={[10, 25, 100]} showInfo={true} showPageSizeSelector={true} displayMode="full"></Pager>
                <Paging visible={true} defaultPageSize={10}></Paging>
    
                
                
              </DataGrid>
              <Scrolling columnRenderingMode="virtual" useNative={false} />
            </div>
          </div>

        </React.Fragment>
      );

}