import gifIcon from './assets/images/gif.png';
import jpegIcon from './assets/images/jpg.png';
import pngIcon from './assets/images/png.png';
import psdIcon from './assets/images/psd.png';
import aiIcon from './assets/images/ai.png';
import bmpIcon from './assets/images/bmp.png';

const appInfo = {
    title: 'Düşyeri Kütüphane',
    //apiUrl: 'https://api2.dusyeri.com/api',
    apiUrl: 'https://api2-test.dusyeri.com/api',
    //apiUrl: 'http://localhost:5210/api',
    contentTypeToImage: (t) => {
        switch (t) {
            case 'application/postscript': return aiIcon;
            case 'image/bmp': return bmpIcon;
            case 'image/gif': return gifIcon;
            case 'image/jpeg': case 'image/jpg': return jpegIcon;
            case 'image/png': return pngIcon;
            case 'application/octet-stream': default: return psdIcon;
        }

    }
};
export default appInfo;

