import CustomStore from "devextreme/data/custom_store";
import appInfo from "../app-info";
import { useContext, createContext } from "react";
import notify from "devextreme/ui/notify";

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

function GamesProvider(props) {
    const store = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            if (!loadOptions.sort)
                loadOptions.sort = [{ desc: false, selector: 'id' }]
            let params = '?';
            [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
            });
            params = params.slice(0, -1);
            return fetch(`${appInfo.apiUrl}/games${params}`,{
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token 
                  }
                })
                .then((response) => response.json())
                .then((data) => ({
                    data: data.data,
                    totalCount: data.totalCount,
                    summary: data.summary,
                    groupCount: data.groupCount
                }))
                .catch(() => { throw new Error('Data Loading Error'); });
        }

    });

    return (
        <GamesContext.Provider value={{ store }} {...props} />
    );
}


const GamesContext = createContext({ loading: false });
const useGames = () => useContext(GamesContext);

export { GamesProvider, useGames }
