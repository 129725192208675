import { Button, DataGrid } from 'devextreme-react';
import React, { useEffect, useRef, useState } from 'react';
import appInfo from '../../app-info';
import { useLibraryWords } from '../../contexts/librarywords';
import './librarywords.scss';
import { Button as Btn } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import SelectBox from 'devextreme-react/select-box';
import copy from "copy-to-clipboard";
import {
  Column,
  Paging,
  Pager,
  Selection,
  FilterRow,
  FilterPanel,
  Editing,
  Popup,
  Form,
  RequiredRule,
  Sorting,
  Scrolling,
  Lookup
} from 'devextreme-react/ui/data-grid';
import { Popup as Pp } from 'devextreme-react/popup';

import { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { ProgressBar } from 'devextreme-react/progress-bar';
import { FileUploader } from 'devextreme-react/file-uploader';
import { ScrollView } from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';

import psdIcon from '../../assets/images/psd.png';
import aiIcon from '../../assets/images/ai.png';
import EmployeeTagBoxComponent from '../../components/selection-box-input/EmployeeTagBoxComponent';


import TileView, {
  Item
} from 'devextreme-react/tile-view';

export default function Librarywords ()  {
  const { store } = useLibraryWords();
  const mainGridRef = useRef()
  const [speechPopupVisible, setspeechPopupVisible] = useState(false);
  const [mediasPopupVisible, setMediasPopupVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState({ data: { id: '', images: [] } });
  const [imagesRow, setImagesRow] = useState({});
  const [idCurrentRow, setIdCurrentRow] = useState(null);
  const [langSpeech, setLangSpeech] = useState(`tr`);
  const [hasSpeech, setHasSpeech] = useState(false);
  const [speechData, setSpeechData]= useState('');
  const languages = [
    { name: 'Evrensel', value: 'Universal', },
    { name: 'Türkçe', value: 'tr', },
    { name: 'İngilizce', value: 'en', },
    { name: 'Almanca', value: 'de', },
    { name: 'Rusça', value: 'ru', },
    { name: 'İspanyolca', value: 'es', },
    { name: 'Portekizce', value: 'pt', },
    { name: 'Çince', value: 'ch', },
    { name: 'Endonezce', value: 'in', },
    { name: 'Hintçe', value: 'hn', }
  ];
  const [gridDataSource, setGridDataSource] = useState([]);
  
  useEffect(() => {
      GetGameList()  
  }, []);

  
  const headersApi = {
    Authorization: "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token 
}

function GetGameList() {
  fetch(`${appInfo.apiUrl}/games`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token,
      'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
  })
    .then(function (data) {
      return data.json(); // call the json method on the response to get JSON
  })
  .then(function (json) {
    setGridDataSource(json.data)
  })
}



  function removeMedias(e) {
    fetch(`${appInfo.apiUrl}/files/removelibrarywordmedia/${e.id}`, {
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token
      }
    })
      .then(response => response.status)
      .then(data => {
        currentRow.data.images = currentRow.data.images.filter(elem => elem.id !== e.id);
        setCurrentRow(currentRow);
        imagesRow.images = imagesRow.images.filter(elem => elem.id !== e.id);
        setImagesRow(imagesRow);
      })
  }

  function removeMediaState() {
    fetch(`${appInfo.apiUrl}/librarywords/${idCurrentRow}`,{
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token 
      }
    })
      .then(response => response.json())
      .then(data => {
        setImagesRow(data)
        data.images.map(elem => imagesTileTemplate(elem))
      })
  }
  function removeMediaStateGetId(id) {
    fetch(`${appInfo.apiUrl}/librarywords/${id}`,{
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token 
      }
    })
      .then(response => response.json())
      .then(data => {
        setImagesRow(data)
        data.images.map(elem => imagesTileTemplate(elem))
      })
  }

  function cellRenderMedias(row) {
    
    return <Button stylingMode='text' type='normal' icon='fa fa-images' onClick={() => { setCurrentRow(row);removeMediaStateGetId(row.data.id); setIdCurrentRow(row.data.id); setMediasPopupVisible(true) }}></Button>;
  }

  function cellRenderText(row) {
    return row.value ? row.value : <div style={{ textAlign: 'center' }}><i className='fa fa-remove' style={{ 'color': 'red' }}></i></div>;
  }

  function cellRenderSpeechTurkish(row) {
    return cellRenderContent(row.data.hasTurkishSpeech, 'tr', row);
  }

  function cellRenderSpeechEnglish(row) {
    return cellRenderContent(row.data.hasEnglishSpeech, 'en', row);
  }

  function cellRenderSpeechGerman(row) {
    return cellRenderContent(row.data.hasGermanSpeech, 'de', row);
  }

  function cellRenderSpeechRussian(row) {
    return cellRenderContent(row.data.hasRussianSpeech, 'ru', row);
  }
  function cellRenderSpeechSpanish(row) {
    return cellRenderContent(row.data.hasSpanishSpeech, 'sp', row);
  }
  function cellRenderSpeechChinese(row) {
    return cellRenderContent(row.data.hasChineseSpeech, 'ch', row);
  }
  function cellRenderSpeechHindi(row) {
    return cellRenderContent(row.data.hasHindiSpeech, 'hn', row);
  }
  function cellRenderSpeechIndonesian(row) {
    return cellRenderContent(row.data.hasIndonesianSpeech, 'in', row);
  }
  function cellRenderSpeechPortugalian(row) {
    return cellRenderContent(row.data.hasPortugueseSpeech, 'pt', row);
  }

  function updateWordImage(m, e) {
    fetch(`${appInfo.apiUrl}/files/updatelibrarywordimage/${m.id}/${e.value}`, {
      method: "PUT",
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token
      },
      body: JSON.stringify({

      })
    })
      .then(response => response.json())
      .then(data => {
        mainGridRef.current.instance.refresh();
        return data
      });
  }

  function cellRenderContent(h, lang, row) {
    return (
      <>
        {row.data ? <Button stylingMode='text' type={h ? 'success' : 'danger'} icon='fa fa-play' onClick={() => { setIdCurrentRow(row.data.id); setSpeechData(`${appInfo.apiUrl}/files/getspeech/${row.data.id}/${lang}?${new Date().getTime()}`); openSpeechPopupVisible(h, lang, row) }}></Button> : ''}
      </>
    )
  }

  function cellRenderNumber(e) {
    return (
      <>
        {(((e.component.pageSize() * e.component.pageIndex()) + e.row.rowIndex) + 1).toString().padStart(2, '0')}
      </>
    )
  }

  function cellRenderId(e) {
    return (
      <>
        <Button stylingMode='text' type='normal' icon='fa fa-copy' title={e.row.data.id} onClick={() => {
          copy(e.row.data.id);
          notify(`${e.row.data.id} başarıyla panoya kopyalandı!`, 'success', 5000);
        }}></Button>
      </>
    )
  }

  function cellRenderTurkishText(e) {
    return (
      <>
        {e.row.data.turkishText}
        <div className='d-flex overflow-hidden py-2' style={{ paddingLeft: '10px' }}>
          {e.row.data.images.slice(0, 5).map((elem, index) => {
            return (
              <div key={index} className='rounded-circle p-1 border shadow-sm position-relative bg-white' style={{ marginLeft: '-10px' }}>
                <img src={`${appInfo.apiUrl}/files/librarywordimage/${elem.id}`} style={{ objectFit: 'cover' }} alt='' className='rounded-circle' height={24} width={24} /></div>
            )
          })
          }
        </div>
      </>
    )
  }

  function openSpeechPopupVisible(hasSpeech, lang, row) {
    setspeechPopupVisible(true);
    setCurrentRow(row);
    setLangSpeech(lang);
    setHasSpeech(hasSpeech);

  }
  function closePopupMedias(){
    setMediasPopupVisible(false);
    setImagesRow({});
  }
  function closePopupWordAddUpdate(){

  }
  function OpenPopupWordAddUpdate(e){
  }
  

  function onFilesUploaded(e) {
    e.component.reset();
    mainGridRef.current.instance.refresh();
    removeMediaState();
    // setspeechPopupVisible(false);
    // setMediasPopupVisible(false);
  }
  function onFilesUploadedAudio(e) {
    e.component.reset();
    mainGridRef.current.instance.refresh();
    setSpeechData(`${appInfo.apiUrl}/files/getspeech/${idCurrentRow}/${langSpeech}?${new Date().getTime()}`);
    setHasSpeech(true); 
    // setspeechPopupVisible(false);
    // setMediasPopupVisible(false);
  }
  

  function cellRenderStats(row) {
    var v = 0;
    v += row.data.turkishText ? 1 : 0;
    v += row.data.englishText ? 1 : 0;
    v += row.data.germanText ? 1 : 0;
    v += row.data.russianText ? 1 : 0;
    v += row.data.spanishText ? 1 : 0;
    v += row.data.chineseText ? 1 : 0;
    v += row.data.hindiText ? 1 : 0;
    v += row.data.indonesianText ? 1 : 0;
    v += row.data.portugueseText ? 1 : 0;
    v += row.data.hasTurkishSpeech ? 1 : 0;
    v += row.data.hasEnglishSpeech ? 1 : 0;
    v += row.data.hasGermanSpeech ? 1 : 0;
    v += row.data.hasRussianSpeech ? 1 : 0;
    v += row.data.hasSpanishSpeech ? 1 : 0;
    v += row.data.hasChineseSpeech ? 1 : 0;
    v += row.data.hasHindiSpeech ? 1 : 0;
    v += row.data.hasIndonesianSpeech ? 1 : 0;
    v += row.data.hasPortugueseSpeech ? 1 : 0;

    return <ProgressBar max={18} min={0} value={v} showStatus={false} />
  }

  function onRowUpdating(e) {
    e.newData = { ...e.oldData, ...e.newData };
  }

  function onRowInserting(e) {

  }

  function removeSpeechs(e, langRemoveSpeech) {
    fetch(`${appInfo.apiUrl}/files/removespeech/${e.id}/${langRemoveSpeech}`, {
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token
      }
    })
      .then(response => response.status)
      .then(data => {
        mainGridRef.current.instance.refresh();
          setHasSpeech(false); 
          setSpeechData('');
        return data;
      })
  }

  function imagesTileTemplate(elem) {  
    return (
      <div key={elem.id} className="col col-md-4 col-lg-2">
        <div className='card m-1'>
          <div style={{ position: 'absolute', left: '0', width: '100%', paddingTop: '1px' }}>
            <button
              type="button"
              className='btn btn-sm btn-danger m-1'
              onClick={() => {
                let result = confirm("<i>Bu kayıt tamamen silinecektir. Devam etmek istiyor musunuz?</i>", "Uyarı!");
                result.then((dialogResult) => {
                  if (dialogResult) {
                    removeMedias(elem)
                    removeMediaState()
                  }
                });
              }}
            >
              <i className="fa fa-trash-can"></i>
            </button>
            <a
              type="button"
              className='btn btn-sm btn-primary m-1 text-white'
              href={`${appInfo.apiUrl}/files/downloadlibrarymedia/${elem.id}`}>
              <i className="fa fa-download"></i>
            </a>
          </div>
          <img className='card-img-top' style={{ objectFit: 'cover' }} height='150' key={`imageThumbnail_${elem.id}`} alt="" src={renderImgTop(elem)} />
          <div className='card-body'>
            <div className='file-name-box py-2' title={elem.fileName}>{elem.fileName}</div>
            <SelectBox dataSource={languages} valueExpr='value' displayExpr='name' defaultValue={elem.language} onItemClick={(e) => { updateWordImage(elem, e.itemData) }} />
          </div>
        </div>
      </div>
    );
  }

  function renderImgTop(elem) {
    switch (elem.contentType) {
      case 'application/postscript': return aiIcon;
      case 'application/octet-stream': return psdIcon;
      default: return `${appInfo.apiUrl}/files/librarywordimage/${elem.id}`;
    }
  }  

    function cellTemplate(container, options) {
        const noBreakSpace = '\u00A0';
        const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
        container.textContent = text || noBreakSpace;
        container.title = text;
    }

    function calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === 'search' && typeof (filterValue) === 'string') {
            return [this.dataField, 'contains', filterValue];
        }
        return function(data) {
            return (data.AssignedEmployee || []).indexOf(filterValue) !== -1;
        };
    }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Kelimeler</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <DataGrid columnWidth={188} ref={mainGridRef} id='mainGrid' dataSource={store} showBorders={true} rowAlternationEnabled={true} hoverStateEnabled={true} onRowInserting={onRowInserting} onRowUpdating={onRowUpdating} remoteOperations={true}>
            <Editing allowAdding={true} allowDeleting={true} allowUpdating={true} mode="popup" useIcons={true}>
              <Popup title="Kelime Ekle/Düzenle" showTitle={true} dragEnabled={false} fullScreen={false} height="450"
              onHiding={() => closePopupWordAddUpdate()} onShowing={(e)=>OpenPopupWordAddUpdate(e)}>
              </Popup>
              <Form colCount={1} labelLocation="top">
                <GroupItem caption="Çeviriler" colCount={5}>
                  <SimpleItem dataField='turkishText'></SimpleItem>
                  <SimpleItem dataField='englishText'></SimpleItem>
                  <SimpleItem dataField='germanText'></SimpleItem>
                  <SimpleItem dataField='russianText'></SimpleItem>
                  <SimpleItem dataField='spanishText'></SimpleItem>
                  <SimpleItem dataField='chineseText'></SimpleItem>
                  <SimpleItem dataField='hindiText'></SimpleItem>
                  <SimpleItem dataField='indonesianText'></SimpleItem>
                  <SimpleItem dataField='portugueseText'></SimpleItem>

                </GroupItem>
                <GroupItem cssClass="second-group" >
                   <SimpleItem dataField='gamesId'></SimpleItem> 
                </GroupItem>
                  
              </Form>
            </Editing>
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <Selection mode="single" />
            <Sorting mode="single"></Sorting>
            <Pager visible={true} allowedPageSizes={[10, 25, 100]} showInfo={true} showPageSizeSelector={true} displayMode="full"></Pager>
            <Paging visible={true} defaultPageSize={10}></Paging>

            <Column name="column0" caption="#" dataField={'index'} cellRender={cellRenderNumber} alignment="left" width={60}></Column>
            <Column name="column00" type="buttons" width={60}></Column>
            <Column name="column22" caption="Durum" cellRender={cellRenderStats}></Column>
            <Column name="column1" caption="Id" dataField={'id'} cellRender={cellRenderId} alignment="left" width={60}></Column>
            <Column name="column2" caption="Görseller" cellRender={cellRenderMedias} alignment="left" width={60}></Column>
            <Column name="column21" caption="Tarih" dataField='dateCreated' dataType="date"></Column>
            <Column name="column3" alignment="left" caption="Türkçe" dataField={'turkishText'} allowSorting={true} sortIndex={0} cellRender={cellRenderTurkishText} width={200}>
              <Label text='Türkçe Çeviri'></Label>
              <RequiredRule message="Türkçe çeviri boş bırakılamaz!" />
            </Column>
            <Column name="column4" alignment="center" caption="Türkçe Ses" dataField={'hasTurkishSpeech'} cellRender={cellRenderSpeechTurkish}></Column>
            <Column name="column5" alignment="left" caption="İngilizce" dataField={'englishText'} cellRender={cellRenderText}></Column>
            <Column name="column6" alignment="center" caption="İngilizce Ses" dataField={'hasEnglishSpeech'} cellRender={cellRenderSpeechEnglish}></Column>
            <Column name="column7" alignment="left" caption="Almanca" dataField={'germanText'} cellRender={cellRenderText}></Column>
            <Column name="column8" alignment="center" caption="Almanca Ses" dataField={'hasGermanSpeech'} cellRender={cellRenderSpeechGerman}></Column>
            <Column name="column9" alignment="left" caption="Rusca" dataField={'russianText'} cellRender={cellRenderText}></Column>
            <Column name="column10" caption="Rusca Ses" dataField={'hasRussianSpeech'} alignment="center" cellRender={cellRenderSpeechRussian}></Column>
            <Column name="column11" alignment="left" caption="Ispanyolca" dataField={'spanishText'} cellRender={cellRenderText}></Column>
            <Column name="column12" caption="Ispanyolca Ses" dataField={'hasSpanishSpeech'} alignment="center" cellRender={cellRenderSpeechSpanish}></Column>
            <Column name="column13" alignment="left" caption="Çince" dataField={'chineseText'} cellRender={cellRenderText}></Column>
            <Column name="column14" caption="Çince Ses" dataField={'hasChineseSpeech'} alignment="center" cellRender={cellRenderSpeechChinese}></Column>
            <Column name="column15" alignment="left" caption="Hintçe" dataField={'hindiText'} cellRender={cellRenderText}></Column>
            <Column name="column16" caption="Hintçe Ses" dataField={'hasHindiSpeech'} alignment="center" cellRender={cellRenderSpeechHindi}></Column>
            <Column name="column17" alignment="left" caption="Endonezce" dataField={'indonesianText'} cellRender={cellRenderText}></Column>
            <Column name="column18" caption="Endonezce Ses" dataField={'hasIndonesianSpeech'} alignment="center" cellRender={cellRenderSpeechIndonesian}></Column>
            <Column name="column19" alignment="left" caption="Portekizce" dataField={'portugueseText'} cellRender={cellRenderText}></Column>
            <Column name="column20" caption="Portekizce Ses" dataField={'hasPortugueseSpeech'} alignment="center" cellRender={cellRenderSpeechPortugalian}></Column>
            <Column name="column25" caption="Kullanıldığı Oyunlar" dataField={'gamesIdListObj'} alignment="center" cellRender={cellRenderText}></Column>
            <Column
              dataField="gamesId"
              caption="Kullanıldığı Oyunlar"
              width={200}
              allowSorting={false}
              allowFiltering={false}
              editCellComponent={EmployeeTagBoxComponent}
              cellTemplate={cellTemplate}
              visible={false} // Kolonun görünürlüğünü kapatmak için
              calculateFilterExpression={calculateFilterExpression}>
              <Lookup
                dataSource={gridDataSource}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
          </DataGrid>
          <Scrolling columnRenderingMode="virtual" useNative={false} />
        </div>
      </div>
      <Pp visible={speechPopupVisible} onHiding={() => setspeechPopupVisible(false)} showTitle={true} title="Dosyalar" height={350} width="360">
        {hasSpeech ?
          <>
            <div className='p-2 mb-2'>
              <audio controls src={speechData} />
              <button
                width={120}
                type="button"
                className='btn btn-danger m-1'
                onClick={() => {
                  let result = confirm("<i>Bu kayıt tamamen silinecektir. Devam etmek istiyor musunuz?</i>", "Uyarı!");
                  result.then((dialogResult) => {
                    if (dialogResult) {
                      removeSpeechs(currentRow.data, langSpeech)
                      
                      //setspeechPopupVisible(false);
                      
                    }
                  });
                }}
              >
                <i className="fa fa-trash-can"></i>
              </button>
            </div>
            <div className='text-center text-muted'>Değiştirmek istiyorsanız dosya seçiniz...</div>
          </>
          :
          <>
            <div className='text-center text-danger'>Ses dosyası yok !</div>
            <div className='text-center text-muted'>Ses dosyası eklemek için dosya seçiniz...</div>

          </>
        }
        <FileUploader onFilesUploaded={onFilesUploadedAudio} selectButtonText='Dosya Seç' uploadMethod='POST' uploadMode='instantly' accept='audio/*' uploadHeaders={headersApi} uploadUrl={`${appInfo.apiUrl}/files/updatespeech/${currentRow.data.id}/${langSpeech}`} />
      </Pp>
      <Pp visible={mediasPopupVisible} onHiding={() => closePopupMedias()} showTitle={true} title="Görseller">
        <ScrollView width='100%' height='100%' useNative={false}>
          <div className='p-4'>
            <div className='card mb-2'>
              <div className='card-header'>Görseller</div>
              <div className='card-body'>
                <div className='text-center text-muted'>Görsel eklemek için dosya seçiniz...</div>
                <FileUploader onFilesUploaded={onFilesUploaded} selectButtonText='Dosya Seç' uploadMethod='POST' uploadMode='instantly' accept='image/*, .psd, .ai' multiple={true} uploadHeaders={headersApi} uploadUrl={`${appInfo.apiUrl}/files/addlibrarywordimage/${currentRow.data.id}`} />
                <hr />
                {/*
                <TileView dataSource={currentRow.data.images} itemRender={imagesTileTemplate} baseItemWidth={240} baseItemHeight={240} direction='horizontal' width='100%'>
                </TileView>
                */}
                <div className='row' >
                  {
                    imagesRow.images ?
                      imagesRow.images.map(elem => imagesTileTemplate(elem))
                      : currentRow.data.images.map(elem => imagesTileTemplate(elem))
                  }
                </div>
              </div>
            </div>
            {/* <div className='card mb-2'>
              <div className='card-header'>Videolar</div>
              <div className='card-body'>
                <div className='row row-cols-1 row-cols-md-4'>
                  {
                    currentRow.data.videos ?
                      currentRow.data.videos.map((elem, index) => (
                        <div key={index} className='col'>
                          <div className='card m-1'>
                            <div className='card-body d-flex flex-column align-items-center'>
                              <video src={`${appInfo.apiUrl}/files/librarywordvideo/${elem.id}`} controls height={120} className='mb-2' />
                              <Btn
                                width={120}
                                text="Sil"
                                type="default"
                                stylingMode="contained"
                                onClick={() => {
                                  let result = confirm("<i>Bu kayıt tamamen silinecektir. Devam etmek istiyor musunuz?</i>", "Uyarı!");
                                  result.then((dialogResult) => {
                                    if (dialogResult) {
                                      removeMedias(elem)
                                      setMediasPopupVisible(false)
                                    }

                                  });

                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                      :
                      ''
                  }
                </div> */}
            {/* <div className='text-center text-muted'>Video eklemek için dosya seçiniz...</div>
                <FileUploader onFilesUploaded={onFilesUploaded} selectButtonText='Dosya Seç' uploadMethod='POST' uploadMode='instantly' accept='video/*' uploadUrl={`${appInfo.apiUrl}/files/addlibrarywordvideo/${currentRow.data.id}`} /> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </ScrollView>
      </Pp>
    </React.Fragment>
  );
};
