export const navigation = [
  {
    text: 'Ana Sayfa',
    path: '/',
    icon: 'home'
  },
  {
    text: 'Kelimeler',
    path: '/librarywords',
    icon: 'comment'
  },
  {
    text: 'Oyunlar',
    path: '/games',
    icon: 'fa fa-gamepad'
  }
];
