import React, { useEffect, useState } from 'react';
import appInfo from '../../app-info';
import './home.scss';
import CountUp from 'react-countup';
import { Chart, Series } from 'devextreme-react/chart';
import { DataGrid, Column } from 'devextreme-react/ui/data-grid';

export default function Home() {
  const [stats, setStats] = useState([])
  const [cartData, setCartData] = useState([])
  function populateCartData(d) {
    return [{ name: 'Türkçe', value: d.words }, { name: 'İngilizce', value: d.englishContent }, { name: 'Almanca', value: d.germanContent }, { name: 'Arapça', value: d.arabicContent },];
  }
  useEffect(() => {
    fetch(`${appInfo.apiUrl}/LibraryWords/stats`,{
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('currentUser')).token //<--
      }
    })
      .then(response => response.json())
      .then(data => {
        setStats(data);
        setCartData([
          { name: 'Türkçe', value: data.words / data.words * 100 },
          { name: 'İngilizce', value: data.englishContent / data.words * 100 },
          { name: 'Almanca', value: data.germanContent / data.words * 100 },
          { name: 'Rusca', value: data.russianContent / data.words * 100 },
          { name: 'Ispanyolca', value: data.spanishContent / data.words * 100 },
          { name: 'Çince', value: data.chineseContent / data.words * 100 },
          { name: 'Hintçe', value: data.hindiContent / data.words * 100 },
          { name: 'Endonezce', value: data.indonesianContent / data.words * 100 },
          { name: 'Portekizce', value: data.portugueseContent / data.words * 100 }
        ]);
      })
  }, [])

  // console.log(stats.lastWords)

  function floorStats(e, t) {
    return Math.floor(Number(e) / Number(t) * 100);
  }

  function headerCellRender(e) {
    var flag = 'tr';
    switch (e.column.dataField) {
      case "turkishText": flag = 'tr'; break;
      case "englishText": flag = 'gb'; break;
      case "germanText": flag = 'de'; break;
      case "russianText": flag = 'ru'; break;
      case "spanishText": flag = 'es'; break;
      case "chineseText": flag = 'cn'; break;
      case "hindiText": flag = 'in'; break;
      case "indonesianText": flag = 'id'; break;
      case "portugueseText": flag = 'pt'; break;
      default: flag='tr'; break;
    }
    return (
      <>
        <img src={`https://flagcdn.com/${flag}.svg`} width="24" alt={e.column.caption} className='mr-2' />
        {e.column.caption}
      </>
    )
  }

  return (
    <React.Fragment>
      <div className='content-block'>
        <div className='dx-card responsive-paddings mb-4'>
          <div className='d-flex flex-wrap justify-content-center'>
            <div className='stats-box bg-danger col-3'>
              <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={stats.words / stats.words * 100} duration={2} /></h1>
                    <h2>Türkçe</h2>
                    <h4 className='text-white-50'>{stats.words} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='stats-box bg-info col-3'>
             <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={floorStats(stats.englishContent, stats.words)} duration={2} /></h1>
                    <h2>İngilizce</h2>
                    <h4 className='text-white-50'>{stats.englishContent} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='stats-box col-3 bg-primary'>
             <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={floorStats(stats.spanishContent, stats.words)} duration={2} /></h1>
                    <h2>Ispanyolca</h2>
                    <h4 className='text-white-50'>{stats.spanishContent} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='stats-box col-3 bg-warning'>
              <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={floorStats(stats.russianContent, stats.words)} duration={2} /></h1>
                    <h2>Rusca</h2>
                    <h4 className='text-white-50'>{stats.russianContent} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='stats-box col-3 bg-info'>
              <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={floorStats(stats.germanContent, stats.words)} duration={2} /></h1>
                    <h2>Almanca</h2>
                    <h4 className='text-white-50'>{stats.germanContent} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='stats-box col-3 bg-danger'>
              <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={floorStats(stats.chineseContent, stats.words)} duration={2} /></h1>
                    <h2>Çince</h2>
                    <h4 className='text-white-50'>{stats.chineseContent} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='stats-box col-3 bg-warning'>
              <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={floorStats(stats.hidiContent, stats.words)} duration={2} /></h1>
                    <h2>Hintçe</h2>
                    <h4 className='text-white-50'>{stats.hidiContent} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='stats-box col-3 bg-primary'>
              <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={floorStats(stats.indonesianContent, stats.words)} duration={2} /></h1>
                    <h2>Endonezce</h2>
                    <h4 className='text-white-50'>{stats.indonesianContent} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='stats-box col-3 bg-success'>
              <div className='card-body'>
                <div className='d-flex'>
                  <div className='p-2'>
                    <h1>%<CountUp start={0} end={floorStats(stats.portugueseContent, stats.words)} duration={2} /></h1>
                    <h2>Portekizce</h2>
                    <h4 className='text-white-50'>{stats.portugueseContent} / {stats.words}</h4>
                  </div>
                  <div className='p-2'>
                    <i className='fa fa-globe fa-5x text-white-50'></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='dx-card responsive-paddings mb-4'>
          <div>
            <div>
              <div style={{width: '100%'}} className='card'>
                <div className='card-header'>
                  <h4>Çeviri Oranları</h4>
                </div>
                <div className='card-body' style={{width: '230vh'}}>
                  <Chart id="chart" dataSource={cartData}>
                    <Series
                      valueField="value"
                      argumentField="name"
                      name="Çeviri Oranı"
                      type="bar"
                      color="#d0d0d0" />
                  </Chart>
                </div>
              </div>
            </div>
            <div>
              <div className='card mt-4'>
                <div className='card-header'>
                  <h4>Son Eklenenler</h4>
                </div>
                <div className='card-body'>
                  <DataGrid dataSource={stats.lastWords} showBorders={true} rowAlternationEnabled={true}>
                    <Column dataField="dateCreated" visible={false}></Column>
                    <Column dataField="turkishText" caption="Türkçe" headerCellRender={headerCellRender}></Column>
                    <Column dataField="englishText" caption="İngilizce" headerCellRender={headerCellRender}></Column>
                    <Column dataField="germanText" caption="Almanca" headerCellRender={headerCellRender}></Column>
                    <Column dataField="russianText" caption="Rusca" headerCellRender={headerCellRender}></Column>
                    <Column dataField="spanishText" caption="ispanyolca" headerCellRender={headerCellRender}></Column>
                    <Column dataField="chineseText" caption="Çince" headerCellRender={headerCellRender}></Column>
                    <Column dataField="hindiText" caption="Hintçe" headerCellRender={headerCellRender}></Column>
                    <Column dataField="indonesianText" caption="Endonezce" headerCellRender={headerCellRender}></Column>
                    <Column dataField="portugueseText" caption="Portekizce" headerCellRender={headerCellRender}></Column>
                  </DataGrid>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
